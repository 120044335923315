<template>
  <b-dropdown
    variant="link"
    toggle-class="p-0"
    no-caret
    dropleft
  >
    <template #button-content>
      <feather-icon
        icon="MoreVerticalIcon"
        size="16"
        class="align-middle text-body"
      />
    </template>
    <b-dropdown-item :to="editAction">
      <feather-icon icon="EditIcon" />
      <span class="align-middle ml-50">Güncelle</span>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="deleteAction && dataId"
      @click="deleteAction(dataId)"
    >
      <feather-icon icon="XIcon" />
      <span class="align-middle ml-50">Sil</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'

export default {
  name: 'ListButtons',
  components: {
    BDropdown, BDropdownItem,
  },
  props: {
    editAction: {
      type: String,
      required: true,
    },
    dataId: {
      type: String,
      required: false,
      default: null,
    },
    deleteAction: {
      type: Function,
      required: false,
      default: null,
    },
  },
}
</script>

<style scoped>

</style>
